import React, { useState, useEffect } from 'react'
import { Col, Row, Menu } from 'antd';
import vklogo from '../../Image/vk-logo.png'
import { Link, useLocation } from 'react-router-dom'
export default function Nav() {
    let location = useLocation()
    const [current, setCurrent] = useState('');
    // const handleClick = e => {
    //     setCurrent(e.key)
    // };

    useEffect(() => {
        setCurrent(location.pathname)
    }, []);

    return (
        <Row>
            <Col
                xs={24} sm={24} md={24} lg={24} xl={24}
            >
                <div
                    style={{
                        padding: "8px",
                        background: "#87bbed",
                        height: "90px"
                    }}
                >
                    <img
                        height="60px"
                        src={vklogo}
                    />
                </div>
            </Col>
        </Row >
    )
}
