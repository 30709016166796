import React, { useEffect, useState } from 'react'
import { Col, Table, Row, Space, Button, Popconfirm, message } from 'antd'
import axios from 'axios';
import moment from 'moment';
import { GrView } from 'react-icons/gr';
import { MdOutlineDeleteForever } from "react-icons/md";
import { Link } from 'react-router-dom';
import { SelectContruct } from './SelectContruct';
import { getCookie } from '../../getDatabase';

export default function Home() {

    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [customerId, setCustomerId] = useState(null)

    function handleSelectConstruction(e) {
        setCustomerId(e)
    }

    useEffect(() => {
        setLoading(true)
        getReports();
    }, [success, customerId]);

    const confirm = async (e) => {
        const params = new URLSearchParams();
        params.append('db_user', process.env.React_App_DB_USER);
        params.append('db_password', process.env.React_App_DB_PASSWORD);
        params.append('db', process.env.React_App_DB);

        params.append('data', JSON.stringify({ dc_id: e, }));

        return await axios.post(
            `${process.env.React_App_URL}/delete/deleteDailyConstructById.php`, params
        )
            .then(async function (response) {
                console.log(response.data)
                if (response?.data === 'success') {
                    message.success('ជោគជ័យ!');
                    setSuccess(true)
                } else {
                    message.error('លុបមិនជោគជ័យ!');
                }

            });


    }
    const getReports = async () => {
        const params = new URLSearchParams();
        params.append('db_user', process.env.React_App_DB_USER);
        params.append('db_password', process.env.React_App_DB_PASSWORD);
        params.append('db', process.env.React_App_DB);

        params.append('data', JSON.stringify({ page: page, pageSize: pageSize, customerId: customerId, userId: getCookie("u_id_report") }))

        return await axios.post(
            `${process.env.React_App_URL}/get/getDailyConstruct.php`, params
        )
            .then(async function (response) {

                if (await response?.data !== 'Cannot select' && await response?.data !== 'notuser') {
                    setReports(response?.data)
                    // console.log(response?.data)
                    setLoading(false)
                    setSuccess(false)
                    return response?.data;
                } else {
                    setSuccess(false)
                    setLoading(false)
                    return [];
                }
            });
    }
    const columns = [
        {
            title: 'បរិច្ឆេទ',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render:
                text =>
                    <Space size="middle">
                        {
                            moment(text).format("DD-MM-YYYY")
                        }
                    </Space>,
        },
        {
            title: 'ឈ្មោះការដ្ឋាន',
            dataIndex: 'constructionName',
            key: 'constructionName',

        },
        {
            key: 'action',

            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/report/${record.dc_id}`}>
                        <Button
                            type="primary"
                            shape="circle"
                            size='middle'
                            icon={
                                <GrView
                                    style={{
                                        marginTop: "4px",
                                    }}
                                    className='viewResult'
                                />
                            }
                        />
                    </Link>
                    {/* <Popconfirm
                        title="តើអ្នកចង់លុបមែនទេ?"
                        onConfirm={() => confirm(record.dc_id)}
                        okText="ព្រម"
                        cancelText="មិនព្រម"
                    >
                        <Button
                            type="primary"
                            shape="circle"
                            danger
                            size='middle'
                            icon={
                                <MdOutlineDeleteForever
                                    style={{
                                        marginTop: "4px",
                                    }}
                                    className='viewResult'
                                />
                            }
                        />
                    </Popconfirm> */}
                </Space>
            ),
        },
    ];

    return (
        <Row
            style={{
                maxHeight: "1080px"
            }}
        >
            <Col xs={24} sm={24} md={3} lg={3} xl={3}></Col>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}></Col>
            {/* <Col xs={24} sm={24} md={18} lg={18} xl={18}
                style={{
                    marginTop: "-15px",
                    padding: "10px",

                }}
            >
                <SelectContruct title={"ជ្រើស់រើសរបាយការណ៍"} setValue={handleSelectConstruction} />

            </Col> */}
            <Col xs={24} sm={24} md={3} lg={3} xl={3}></Col>
            <Col xs={24} sm={24} md={3} lg={3} xl={3}></Col>
            <Col
                xs={24} sm={24} md={18} lg={18} xl={18}
                style={{
                    marginTop: "-20px",
                    padding:10
                }}
            >
                <Table
                    columns={columns}
                    dataSource={reports?.data}
                    loading={loading}
                    style={{ marginTop: "20px" }}
                    rowKey={record => record?.dc_id}
                    pagination={{
                        position: ["bottomCenter"],
                        size: 'small',
                        total: reports?.totalDoc,
                        pageSizeOptions: false,
                        pageSize: pageSize,
                        onChange: ((page, pageSize) => { setPage(page); setPageSize(pageSize) })
                    }}
                />
            </Col>
            <Col xs={24} sm={24} md={3} lg={3} xl={3}></Col>
        </Row >
    )
}
