import axios from 'axios'
import { Select } from 'antd';
import Cookies from 'js-cookie';
const {Option} = Select

export const createDailyConstruct = async (data) => {
    const params = new FormData();
    params.append('db_user', process.env.React_App_DB_USER);
    params.append('db_password', process.env.React_App_DB_PASSWORD);
    params.append('db', process.env.React_App_DB);

    params.append('data', JSON.stringify(data));
    params.append('startImage', data?.startImage)
    return await axios.post(
        `${process.env.React_App_URL}/create/createDailyConstruct.php`, params,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    )
        .then(async function (response) {
            console.log(response?.data)
        });
}

export const getCustomerConstruction = async () => {
    const params = new URLSearchParams();
    params.append('db_user', process.env.React_App_DB_USER);
    params.append('db_password', process.env.React_App_DB_PASSWORD);
    params.append('db', 'wwvka_vkms', process.env.React_App_DB);
    return await axios.post(
        `${process.env.React_App_URL}/get/getCustomerConstruction.php`, params
    )
        .then(async function (response) {
            if (await response?.data !== 'Cannot select' && await response?.data !== 'notuser') {
                return response?.data;
            } else {
                return [];
            }
        });
}

export function getCookie(cname) {
    return Cookies.get(cname);
    // let name = cname + "=";
    // let decodedCookie = decodeURIComponent(document.cookie?.toString());
    // let ca = decodedCookie.split(';');
    // for (let i = 0; i < ca.length; i++) {
    //     let c = ca[i];
    //     while (c.charAt(0) === ' ') {
    //         c = c.substring(1);
    //     }
    //     if (c.indexOf(name) === 0) {
    //         return c.substring(name.length, c.length);
    //     }
    // }
    // return "";
}

export function delete_cookie(name) {
    Cookies.remove(name)
    // document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export function setCookie(c_name, value, exdays) {
    Cookies.set(c_name, value, { expires: exdays })
    // var exdate = new Date();
    // exdate.setDate(exdate.getDate() + exdays);
    // var c_value = encodeURIComponent(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
    // document.cookie = c_name + "=" + c_value;
}

export function countSelectOption(count) {
    let data = []


    for (let i = 0; i <= count; i++) {
        data.push(i)
    }

    return data?.map(d => <Option value={d} key={d}>{d}</Option>);
}