import { Col, Button, Row, Space, Typography, Divider, Image } from "antd";
import React, { useEffect, useState, createRef } from "react";
import Team from "./Team";
import { BsFillPersonFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import EditWorkResult from "./EditWorkResult";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { FaChartLine } from "react-icons/fa";
import {
  convertImageToBase64,
  getSumByCategory,
  getTotalBuilder,
  getTotalWorker,
  toDataUrl,
} from "../../own-comp";
import TagBuilder from "./TagBuilder";

export default function WorkResult() {
  const [report, setReport] = useState({});
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = React.useState(false);
  const { id } = useParams();

  const ref = createRef(null);
  //   const [image, takeScreenShot] = useScreenshot({
  //     type: "image/jpeg",
  //     quality: 1.0,
  //   });

  //   const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  let navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getConstructById(id);
  }, [success, setLoading]);

  const getConstructById = async (id) => {
    const params = new URLSearchParams();
    params.append("db_user", process.env.React_App_DB_USER);
    params.append("db_password", process.env.React_App_DB_PASSWORD);
    params.append("db", process.env.React_App_DB);
    params.append("data", JSON.stringify({ dc_id: id }));

    return await axios
      .post(
        `${process.env.React_App_URL}/get/getDailyConstructById.php`,
        params
      )
      .then(async function (response) {
        if (
          (await response?.data) !== "Cannot select" &&
          (await response?.data) !== "notuser"
        ) {
          setReport(response?.data);
          setLoading(false);
          setSuccess(false);
          return response?.data;
        } else {
          setLoading(false);
          return [];
        }
      });
  };

  const sumByCat = getSumByCategory(report?.team || []);

  if (loading)
    return (
      <div className="loading-data">
        <Spin indicator={antIcon} />
      </div>
    );

  if (
    loading === false &&
    report?.chiefName === null &&
    report?.constructionName === null &&
    report?.customerId === null
  )
    return (
      <div className="loading-data">
        <h3>មិនត្រឹមត្រូវ, មានបញ្ហាហើយ.</h3>
      </div>
    );

  return (
    <Row
      style={{
        padding: "10px",
      }}
      ref={ref}
    >
      <Col xs={24} sm={24} md={3} lg={3} xl={3}></Col>
      <Col
        xs={24}
        sm={24}
        md={18}
        lg={18}
        xl={18}
        style={{
          background: "#ffd359",
          height: "45px",
          textAlign: "center",
          padding: "10px",
          fontFamily: "Moul",
          fontWeight: "bold",
          fontSize: "12pt",
        }}
      >
        {`${report?.constructionName} - `}
        <Typography.Text
          type="danger"
          style={{ fontFamily: "KhmerOSSiemreap" }}
        >
          {moment(report?.createdAt).format("DD/MM/YYYY")}
        </Typography.Text>
      </Col>
      <Col xs={24} sm={24} md={3} lg={3} xl={3}></Col>
      <Col xs={24} sm={24} md={3} lg={3} xl={3}></Col>
      <Col
        xs={24}
        sm={24}
        md={18}
        lg={18}
        xl={18}
        style={{
          padding: "5px",
          background: "#FFFFFF",
          letterSpacing: -0.3,
        }}
      >
        <Row
          style={{
            marginTop: "10px",
            // fontWeight: "bold",
            fontSize: "15px",
            fontFamily: "KhmerOSSiemreap",
            // color: '#616161',
          }}
        >
          <Col xs={6} style={{ padding: 5 }}>
            <BsFillPersonFill />
            &nbsp;&nbsp;មេកា
          </Col>
          <Col xs={18} style={{ fontWeight: "bold", padding: 5 }}>
            : {`${report?.chiefName}`} ({report?.team?.length} ក្រុម -{" "}
            {sumByCat.total} នាក់)
          </Col>
        </Row>

        <Row
          style={{
            fontSize: "15px",
            marginBottom: "10px",
          }}
        >
          <Col
            xs={24}
            style={{ padding: 5, paddingLeft: 28, color: "#bbbbbb" }}
          >
            ជាង: <b>{sumByCat?.totalBuilder}</b> | ថ្នាំ:{" "}
            <b>{sumByCat.totalPainter}</b> | ដែក:{" "}
            <b>{sumByCat.totalBlacksmit}</b> | ឈើ:{" "}
            <b>{sumByCat?.totalCarpenter}</b> | កប្រុស:{" "}
            <b>{sumByCat?.totalMaleWorker}</b> | កស្រី:{" "}
            <b>{sumByCat?.totalFemaleWorker}</b>
          </Col>
        </Row>

        {report?.team?.map((team) => (
          <Team key={team.dt_id} team={team} />
        ))}

        {/* <Row
          style={{
            fontSize: "15px",
            marginBottom: "15px",
          }}
        >
          <Col xs={6} style={{ padding: 5, paddingLeft: 36 }}>
            បញ្ហា
          </Col>
          <Col xs={18} style={{ padding: 5 }}>
            : <b style={{ color: "red" }}>{report?.challenges}</b>
          </Col>
        </Row> */}

        <Row style={{ marginBottom: 20 }}>
          {report?.hasElectricianPlumber === "1" ? (
            <Col xs={8} md={6} style={{ padding: 5 }}>
              <TagBuilder title={`ជាងទឹកភ្លើង`} />
            </Col>
          ) : null}
          {report?.hasCeilBuilder === "1" ? (
            <Col xs={8} md={6} style={{ padding: 5 }}>
              <TagBuilder title={`ជាងពិដាន`} />
            </Col>
          ) : null}
          {report?.hasBlackSmith === "1" ? (
            <Col xs={8} md={6} style={{ padding: 5 }}>
              <TagBuilder title={`ជាងដែក`} />
            </Col>
          ) : null}
          {report?.hasAirConditionerMan === "1" ? (
            <Col xs={8} md={6} style={{ padding: 5 }}>
              <TagBuilder title={`ជាងម៉ាស៊ីនត្រជាក់`} />
            </Col>
          ) : null}
          {report?.hasMirrorBuilder === "1" ? (
            <Col xs={8} md={6} style={{ padding: 5 }}>
              <TagBuilder title={`ជាងកញ្ជក់`} />
            </Col>
          ) : null}
          {report?.hasCarpenter === "1" ? (
            <Col xs={8} md={6} style={{ padding: 5 }}>
              <TagBuilder title={`ជាងឈើ`} />
            </Col>
          ) : null}
        </Row>

        <Row
          style={{
            fontSize: "15px",
            marginBottom: "10px",
          }}
        >
          <Col
            xs={24}
            style={{ padding: 5, fontWeight: "bold", background: "#e6f7ff" }}
          >
            <FaChartLine style={{ fontSize: 13 }} />
            &nbsp;&nbsp;ការងារថ្ងៃស្អែក:
          </Col>
          <Col xs={24} style={{ padding: 5, paddingLeft: 15 }}>
            <Row>
              {report?.team_tmr?.map((e) => {
                const totalBuilderTmr =
                  parseFloat(e?.blacksmithCount) +
                  parseFloat(e?.builderCount) +
                  parseFloat(e?.carpenterCount) +
                  parseFloat(e?.painterCount);

                const totalWorkerTmr =
                  parseFloat(e?.maleWorkerCount) +
                  parseFloat(e?.femaleWorkerCount);
                return (
                  <>
                    <Col
                      xs={8}
                      style={{ display: "inline-block", fontWeight: "bold" }}
                    >
                      - ក្រុម{e?.teamNumber} (ជ{totalBuilderTmr || 0} ក
                      {totalWorkerTmr || 0})
                    </Col>
                    <Col xs={1}>:</Col>
                    <Col xs={15} style={{ wordWrap: "break-word" }}>
                      {e?.performance}
                    </Col>
                  </>
                );
              })}
            </Row>
            {/* ជាង: <b>{report?.builderCountTmr}</b> | ថ្នាំ:{" "}
            <b>{report?.painterCountTmr}</b> | ប្រុស:{" "}
            <b>{report?.workerCountTmr}</b> | ស្រី:{" "}
            <b>{report?.femaleWorkerCountTmr}</b> */}
          </Col>
        </Row>

        <Row>
          {/* <Col xs={24} style={{ padding: 5 }}>
                        <Button
                            type="primary"
                            size='large'
                            style={{ width: '100%' }}
                            onClick={downloadScreenshot}
                        >
                            ថតអេក្រង់
                        </Button>
                    </Col> */}
          <Col xs={12} style={{ padding: 5 }}>
            <EditWorkResult setSuccess={setSuccess} report={report} id={id} />
          </Col>

          <Col xs={12} style={{ padding: 5 }}>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              onClick={() => navigate(`/`)}
            >
              តារាងលទ្ធផល
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
