import react, { useEffect, useState } from 'react'
import 'antd/dist/antd.css';
import {
  Routes,
  Route,
} from 'react-router-dom'
import Nav from './componnets/Nav/Nav';
import Home from './componnets/Home/Home';
import WorkResult from './componnets/WorkResult/WorkResult';
import { Layout } from 'antd';

import Tab from './componnets/Tabs/Tab';
import Login from './componnets/Login/Login';
import { getCookie } from './getDatabase';
import './App.css'
import { AuthContext } from './context/AuthContext';

const { Footer } = Layout;

function App() {
  let getuser = getCookie("u_id_report");
  const [user, setUser] = useState(true)
  const [auth, setAuth] = useState(getuser !== null && getuser !== undefined ? true : false)

  useEffect(() => {
    if (getuser) {
      setUser(true)
      setAuth(false)
    } else {
      setUser(false)
      setAuth(false)
    }
  }, [auth])

  //   return <>
  //          <Nav />
  //          <Tab setAuth={setAuth} />
  //          <Routes>
  //            <Route path="/" element={<Home />} />
  //            <Route path="/report/:id" element={<WorkResult />} />
  //          </Routes>
  //          <Footer
  //            style={{
  //              textAlign: 'center',
  //              position: "absolute",
  //              bottom: 0,
  //              width: '100%'
  //            }}
  //          >
  //            &#169; គ្រប់រូបភាព និងអត្ថបទត្រូវបានរក្សាសិទ្ធដោយ វីខេ អង្គរ
  //          </Footer>
  //        </>
  // }

  return user ?
    (
      <AuthContext.Provider value={{setAuth,user}}>
      <div style={{
        position: 'relative',
        minHeight: '100vh'
      }}>
        <Nav />
        <Tab />

        <div style={{paddingBottom:'7rem'}}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/report/:id" element={<WorkResult />} />
          </Routes>
        </div>

        <Footer
          style={{
            textAlign: 'center',
            position: "absolute",
            bottom: 0,
            // height: '2.5rem',
            display:'block',
            // marginTop: "-80px",
            width: '100%',
          }}
        >
          &#169; គ្រប់រូបភាព និងអត្ថបទត្រូវបានរក្សាសិទ្ធដោយ<br /> វីខេ អង្គរ
        </Footer>
      </div>
      </AuthContext.Provider>
    )
    :
    <AuthContext.Provider value={{setAuth}}>
    <Login />
    </AuthContext.Provider>

}
export default App
