import React, { useEffect, useState, useContext } from "react";
import {
  Drawer,
  Button,
  Form,
  Row,
  Col,
  Select,
  Input,
  message,
  Switch,
} from "antd";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { SelectLocation } from "../Home/SelectCustomer";
import axios from "axios";
import { FaChartLine } from "react-icons/fa";
import { countSelectOption, getCookie } from "../../getDatabase";
import { AuthContext } from "../../context/AuthContext";
import "./styles.css";
import FormListTeam from "../Home/FormListTeam";

const { Option } = Select;
const { TextArea } = Input;

export default function EditWorkResult({ setSuccess, report, id }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const { setAuth } = useContext(AuthContext);

  const [hasElectricianPlumber, setHasElectricianPlumber] = useState("0");
  const [hasCeilBuilder, setHasCeilBuilder] = useState("0");
  const [hasBlackSmith, setHasBlackSmith] = useState("0");
  const [hasAirConditionerMan, setHasAirConditionerMan] = useState("0");
  const [hasMirrorBuilder, setHasMirrorBuilder] = useState("0");
  const [hasCarpenter, setHasCarpenter] = useState("0");

  const [form] = Form.useForm();

  useEffect(() => {
    if (report) {
      form.setFieldsValue({
        customerId: report?.customerId,
        status: report?.status,
        challenges: report?.challenges,
        femaleWorkerCount: parseInt(report?.femaleWorkerCount),
        painterCount: parseInt(report?.painterCount),
        femaleWorkerCountTmr:
          parseInt(report?.femaleWorkerCountTmr) > 0
            ? parseInt(report?.femaleWorkerCountTmr)
            : null,
        builderCountTmr:
          parseInt(report?.builderCountTmr) > 0
            ? parseInt(report?.builderCountTmr)
            : null,
        painterCountTmr:
          parseInt(report?.painterCountTmr) > 0
            ? parseInt(report?.painterCountTmr)
            : null,
        workerCountTmr:
          parseInt(report?.workerCountTmr) > 0
            ? parseInt(report?.workerCountTmr)
            : null,
        team: report?.team?.map((e) => ({
          ...e,
          isContract: parseInt(e?.isContract, 10) > 0 ? true : false,
          isDone: parseInt(e?.isDone, 10) > 0 ? true : false,
        })),
        team_tmr: report?.team_tmr?.map((e) => ({
          ...e,
          isContract: parseInt(e?.isContract, 10) > 0 ? true : false,
        })),
      });

      console.log({
        customerId: report?.customerId,
        status: report?.status,
        challenges: report?.challenges,
        femaleWorkerCount: parseInt(report?.femaleWorkerCount),
        painterCount: parseInt(report?.painterCount),
        femaleWorkerCountTmr:
          parseInt(report?.femaleWorkerCountTmr) > 0
            ? parseInt(report?.femaleWorkerCountTmr)
            : null,
        builderCountTmr:
          parseInt(report?.builderCountTmr) > 0
            ? parseInt(report?.builderCountTmr)
            : null,
        painterCountTmr:
          parseInt(report?.painterCountTmr) > 0
            ? parseInt(report?.painterCountTmr)
            : null,
        workerCountTmr:
          parseInt(report?.workerCountTmr) > 0
            ? parseInt(report?.workerCountTmr)
            : null,
        team: report?.team?.map((e) => ({
          ...e,
          isContract: parseInt(e?.isContract, 10) > 0 ? true : false,
          isDone: parseInt(e?.isDone, 10) > 0 ? true : false,
        })),
        team_tmr: report?.team_tmr?.map((e) => ({
          ...e,
          isContract: parseInt(e?.isContract, 10) > 0 ? true : false,
        })),
      });

      setHasElectricianPlumber(report?.hasElectricianPlumber);
      setHasCeilBuilder(report?.hasCeilBuilder);
      setHasBlackSmith(report?.hasBlackSmith);
      setHasAirConditionerMan(report?.hasAirConditionerMan);
      setHasMirrorBuilder(report?.hasMirrorBuilder);
      setHasCarpenter(report?.hasCarpenter);
    }
  }, [report, visible]);

  const showDrawer = () => {
    if (!getCookie("u_id_report")) {
      message.warning("សូមចូលគណនីសិន!!");
      setAuth(true);
      return;
    }
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const handleResize = () => {
    if (window.innerWidth <= 992) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  window.addEventListener("resize", handleResize);

  const onFinish = (val) => {
    if (val.team.length <= 0) {
      message.error("សូមបញ្ជូលក្រុម!!");
      return;
    }
    setLoading(true);

    updateDailyConstruct({
      ...val,
      dc_id: id,
      hasElectricianPlumber: hasElectricianPlumber,
      hasCeilBuilder: hasCeilBuilder,
      hasBlackSmith: hasBlackSmith,
      hasAirConditionerMan: hasAirConditionerMan,
      hasMirrorBuilder: hasMirrorBuilder,
      hasCarpenter: hasCarpenter,
      team: val?.team?.map((e) => ({
        ...e,
        isContract: e?.isContract ? 1 : 0,
        isDone: e?.isDone ? 1 : 0,
      })),
      team_tmr: val?.team_tmr?.map((e) => ({
        ...e,
        isContract: e?.isContract ? 1 : 0,
      })),
    });
  };

  const updateDailyConstruct = async (data) => {
    const params = new FormData();
    params.append("db_user", process.env.React_App_DB_USER);
    params.append("db_password", process.env.React_App_DB_PASSWORD);
    params.append("db", process.env.React_App_DB);
    params.append("data", JSON.stringify(data));
    return await axios
      .post(
        `${process.env.React_App_URL}/update/updateDailyConstructById.php`,
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(async function (response) {
        // console.log(response?.data)
        if (
          response?.data !== "error" &&
          response?.data !== "errorsize" &&
          response?.data !== "errorextension"
        ) {
          onClose();
          message.success("កែប្រែបាយការណ៍ជោគជ័យ!!");
          form.resetFields();
          setLoading(false);
          setSuccess(true);
        } else {
          message.error("មានបញ្ហា!!");
        }
      });
  };

  const setConstructionCustomer = (e) => {
    form.setFieldsValue({
      customerId: e,
    });
  };

  // const onSelectResultImage = async (e) => {
  //   let imageFile = e.target.files[0];
  //   if (imageFile?.type?.split("/")[0] === "image") {
  //     setResultImage(await compressImage(imageFile));
  //   } else {
  //     message.error("មិនមែនជា File រូបភាពទេ!!");
  //   }
  // };

  const handleSetFemaleWorkerCount = (e) => {
    let workerCount = 0;
    let team = form.getFieldValue("team");

    team?.map((t) => {
      workerCount += isNaN(parseInt(t?.workerCount))
        ? 0
        : parseInt(t?.workerCount);
    });

    if (e > workerCount) {
      message.warning("មិនអាចមានកម្មករស្រីច្រើនជាងចំនួនកម្មករទាំងអស់ទេ!!");
      form.setFieldsValue({
        femaleWorkerCount: report?.femaleWorkerCount,
      });
      return;
    }
  };

  const handleSetPainterCount = (e) => {
    let builderCount = 0;
    let team = form.getFieldValue("team");

    team?.map((t) => {
      builderCount += isNaN(parseInt(t?.builderCount))
        ? 0
        : parseInt(t?.builderCount);
    });

    if (e > builderCount) {
      message.warning("មិនអាចមានជាងថ្នាំច្រើនជាងចំនួនជាងទាំងអស់ទេ!!");
      form.setFieldsValue({
        painterCount: report?.painterCount,
      });
      return;
    }
  };

  return (
    <div>
      <Button
        type="primary"
        size="large"
        onClick={showDrawer}
        style={{ width: "100%" }}
      >
        កែប្រែ
      </Button>
      <Drawer
        title={<span style={{ fontFamily: "Moul" }}>កែប្រែបាយការណ៍</span>}
        className="customize-drawer"
        placement="right"
        width={isMobile ? "100%" : 736}
        onClose={onClose}
        visible={visible}
      >
        <Form
          encType="multipart/form-data"
          layout="vertical"
          className="customize-form"
          form={form}
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={10}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                // label="ឈ្មោះការដ្ឋាន"
                name="customerId"
                rules={[{ required: true, message: "ទាមទារបំពេញ" }]}
              >
                <SelectLocation
                  title={"ឈ្មោះការដ្ឋាន"}
                  setValue={setConstructionCustomer}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                // label="ដំណាក់កាល"
                name="status"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  placeholder="ដំណាក់កាល"
                  size="large"
                  style={{ width: "100%" }}
                >
                  <Option value="រុះរើសំណង់ចាស់">រុះរើសំណង់ចាស់</Option>
                  <Option value="ការងារទ្រួសត្រាយដី">ការងារទ្រួសត្រាយដី</Option>
                  <Option value="ការងារវាស់វែង">ការងារវាស់វែង</Option>
                  <Option value="រកកែងនៅទីតាំងផ្ទាល់ដី">
                    រកកែងនៅទីតាំងផ្ទាល់ដី
                  </Option>
                  <Option value="សំអាតដី">សំអាតដី</Option>
                  <Option value="បុកភ្ជើរ">បុកភ្ជើរ</Option>
                  <Option value="ជីកដីធ្វើគ្រឹះ">ជីកដីធ្វើគ្រឹះ</Option>
                  <Option value="លុបបំពេញរណ្តៅគ្រឹះ">លុបបំពេញរណ្តៅគ្រឹះ</Option>
                  <Option value="ធ្វើធ្នឹមបាតក្រោម">ធ្វើធ្នឹមបាតក្រោម</Option>
                  <Option value="ចាក់ដីលុបបំពេញផ្ទៃអគារ">
                    ចាក់ដីលុបបំពេញផ្ទៃអគារ
                  </Option>
                  <Option value="ធ្វើសសរ">ធ្វើសសរ</Option>
                  <Option value="ធ្វើធ្នឹម">ធ្វើធ្នឹម</Option>
                  <Option value="បង្គប់ទីប">បង្គប់ទីប</Option>
                  <Option value="ចាក់ប្លង់សេ">ចាក់ប្លង់សេ</Option>
                  <Option value="រៀបឥដ្ឋ">រៀបឥដ្ឋ</Option>
                  <Option value="បង្គប់ហ្គែន">បង្គប់ហ្គែន</Option>
                  <Option value="ធ្វើជណ្តើរ">ធ្វើជណ្តើរ</Option>
                  <Option value="បូកនិងសី">បូកនិងសី</Option>
                  <Option value="ធ្វើដំបូល">ធ្វើដំបូល</Option>
                  <Option value="ដាក់ទ្វា">ដាក់ទ្វា</Option>
                  <Option value="ដាក់បង្អួច">ដាក់បង្អួច</Option>
                  <Option value="ក្រាលការ៉ូ">ក្រាលការ៉ូ</Option>
                  <Option value="បំពាក់គ្រឿងបន្ទប់ទឹក">
                    បំពាក់គ្រឿងបន្ទប់ទឹក
                  </Option>
                  <Option value="ចាប់ជ្រីនិងចាប់ជ្រុង">
                    ចាប់ជ្រីនិងចាប់ជ្រុង
                  </Option>
                  <Option value="បៀកថ្នាំ">បៀកថ្នាំ</Option>
                  <Option value="រុញថ្នាំ">រុញថ្នាំ</Option>
                  <Option value="បៀករ៉ង់និងសម្អាត">បៀករ៉ង់និងសម្អាត</Option>
                  <Option value="បំពាក់អំពូល">បំពាក់អំពូល</Option>
                  <Option value="សម្អាត">សម្អាត</Option>
                  <Option value="កែលំអរចំនុចខ្វះខាត">កែលំអរចំនុចខ្វះខាត</Option>
                  <Option value="រួចរាល់">រួចរាល់</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <br />
          <FormListTeam
            report={report?.team}
            name={"team"}
            type="edit"
            addButtonTitle="បន្ថែមក្រុម"
          />

          {/* <Row gutter={10}>
            <Col xs={12}>
              <Form.Item
                // label="ជ្រើសរើសដំណាក់កាល"
                name="femaleWorkerCount"
                rules={[{ required: true, message: "ទាមទារបំពេញ" }]}
              >
                <Select
                  placeholder="ចំ.កម្មករស្រី"
                  onChange={(e) => handleSetFemaleWorkerCount(e)}
                  style={{ width: "100%" }}
                  size="large"
                >
                  {countSelectOption(20)}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item
                // label="ជ្រើសរើសដំណាក់កាល"
                name="painterCount"
                rules={[{ required: true, message: "ទាមទារបំពេញ" }]}
              >
                <Select
                  placeholder="ចំ.ជាងថ្នាំ"
                  onChange={(e) => handleSetPainterCount(e)}
                  style={{ width: "100%" }}
                  size="large"
                >
                  {countSelectOption(20)}
                </Select>
              </Form.Item>
            </Col>
          </Row> */}

          <Row gutter={10}>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Form.Item
                name="hasElectricianPlumber"
                // label="ជាងទឹកភ្លើង"
              >
                <Switch
                  checkedChildren={<AiOutlineCheck />}
                  unCheckedChildren={<AiOutlineClose />}
                  onChange={(e) =>
                    setHasElectricianPlumber(
                      hasElectricianPlumber === "1" ? "0" : "1"
                    )
                  }
                  checked={hasElectricianPlumber === "1"}
                />
                &emsp;ជាងទឹកភ្លើង
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Form.Item
                name="hasCeilBuilder"
                // label="ជាងពិដាន"
              >
                <Switch
                  checkedChildren={<AiOutlineCheck />}
                  unCheckedChildren={<AiOutlineClose />}
                  onChange={(e) =>
                    setHasCeilBuilder(hasCeilBuilder === "1" ? "0" : "1")
                  }
                  checked={hasCeilBuilder === "1"}
                />
                &emsp;ជាងពិដាន
              </Form.Item>
            </Col>

            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Form.Item
                name="hasBlackSmith"
                // label="ជាងដែក"
              >
                <Switch
                  checkedChildren={<AiOutlineCheck />}
                  unCheckedChildren={<AiOutlineClose />}
                  onChange={(e) =>
                    setHasBlackSmith(hasBlackSmith === "1" ? "0" : "1")
                  }
                  checked={hasBlackSmith === "1"}
                />
                &emsp;ជាងដែក
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Form.Item
                name="hasAirConditionerMan"
                // label="ជាងម៉ាស៊ីនត្រជាក់"
              >
                <Switch
                  checkedChildren={<AiOutlineCheck />}
                  unCheckedChildren={<AiOutlineClose />}
                  onChange={(e) =>
                    setHasAirConditionerMan(
                      hasAirConditionerMan === "1" ? "0" : "1"
                    )
                  }
                  checked={hasAirConditionerMan === "1"}
                />
                &emsp;ជាងម៉ាស៊ីនត្រជាក់
              </Form.Item>
            </Col>

            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Form.Item
                name="hasMirrorBuilder"
                // label="ជាងម៉ាស៊ីនត្រជាក់"
              >
                <Switch
                  checkedChildren={<AiOutlineCheck />}
                  unCheckedChildren={<AiOutlineClose />}
                  onChange={(e) =>
                    setHasMirrorBuilder(hasMirrorBuilder === "1" ? "0" : "1")
                  }
                  checked={hasMirrorBuilder === "1"}
                />
                &emsp;ជាងកញ្ចក់
              </Form.Item>
            </Col>

            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Form.Item
                name="hasCarpenter"
                // label="ជាងឈើ"
              >
                <Switch
                  checkedChildren={<AiOutlineCheck />}
                  unCheckedChildren={<AiOutlineClose />}
                  onChange={(e) =>
                    setHasCarpenter(hasCarpenter === "1" ? "0" : "1")
                  }
                  checked={hasCarpenter === "1"}
                />
                &emsp;ជាងឈើ
              </Form.Item>
            </Col>
          </Row>

          {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item name="challenges">
              <TextArea placeholder="តម្រូវការ/បញ្ហា/ផ្សេងៗ" maxLength={100} />
            </Form.Item>
          </Col> */}

          <Row gutter={10}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <p
                style={{
                  marginTop: "13px",
                  fontSize: "18px",
                  fontFamily: "Moul",
                  // color: "#616161",
                }}
              >
                <FaChartLine /> ការងារថ្ងៃស្អែក៖
              </p>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <FormListTeam
                report={report?.team_tmr || []}
                name={"team_tmr"}
                type="create"
                addButtonTitle="បន្ថែមការងារ (ស្អែក)"
              />
            </Col>
          </Row>

          <br />

          <Form.Item>
            <Button
              type="primary"
              style={{ width: "100%" }}
              loading={loading}
              size="large"
              htmlType="submit"
            >
              កែប្រែ
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}
