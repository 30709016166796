import { Button, Col, Popconfirm, Row, Space } from 'antd';
import React,{useContext} from 'react';
import AddReport from '../Home/AddReport';
import { useNavigate } from 'react-router-dom'
import { AiOutlineLogout } from 'react-icons/ai';
import { delete_cookie } from '../../getDatabase';
import { AuthContext } from '../../context/AuthContext';

export default function Tab() {
    let navigate = useNavigate();

    const {setAuth} = useContext(AuthContext)

    return (
        <Row>
            <Col xs={24} sm={24} md={3} lg={3} xl={3}></Col>
            <Col
                xs={20} sm={21} md={16} lg={17} xl={17}
                >
                <Space
                    style={{
                        marginTop: "15px",
                        fontFamily: "Bayon",
                        fontSize: "15pt",
                        padding: "10px",
                        color: "#1890ff",
                        cursor: "pointer"
                    }}
                // size="middle"
                >
                    <p onClick={() => navigate(`/`)} >!លទ្ធផលការងារ</p>
                    <p style={{ fontWeight: "bold", marginTop: "-5px" }}>|</p>
                    <AddReport />
                </Space>
            </Col >
            <Col xs={2} sm={2} md={2} lg={1} xl={1}
                style={{ marginTop: '20px' }}
            >

                <Popconfirm
                    placement="topRight"
                    title={"តើអ្នកចង់ចាកចេញ?"}
                    onConfirm={() => {
                        delete_cookie("u_id_report");
                        
                        setAuth(true)
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        shape="circle"
                        type="primary" 
                        danger
                        size='large'
                        icon={<AiOutlineLogout style={{ marginTop: "5px" }} />}
                    />
                </Popconfirm>

            </Col >
            <Col xs={24} sm={24} md={3} lg={3} xl={3}></Col>
        </Row>
    )
}
//
