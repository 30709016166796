import { Row, Col, Divider } from "antd";
import React from "react";
import { IoIosPeople } from "react-icons/io";

export default function Team({ team: teamData }) {
  const team = {
    ...teamData,
    isContract: parseInt(teamData?.isContract, 10) > 0 ? true : false,
    isDone: parseInt(teamData?.isDone, 10) > 0 ? true : false,
  };

  const resultWithIsDone = () => {
    if (team?.result !== "") {
      return (
        <>
          {team?.isDone ? (
            <span style={{ color: "#32CD32" }}>(រួចរាល់)</span>
          ) : (
            <>
              {team?.isContract ? (
                <span style={{ color: "rgb(255, 211, 89)" }}>(មិនរួច)</span>
              ) : null}
            </>
          )}{" "}
          {team?.result}
        </>
      );
    }

    return team?.result;
  };

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Row
          style={{
            fontSize: "15px",
            // marginTop: "-10px"
            background: "#EEEEEE",
          }}
        >
          <Col
            xs={6}
            style={{ padding: "5px 0px 5px 5px", fontWeight: "bold" }}
          >
            <IoIosPeople />
            &nbsp;&nbsp;ក្រុម {team?.teamNumber}
          </Col>
          <Col xs={16} style={{ padding: "5px 0px 5px 5px" }}>
            <b>: </b>
            {team?.builderCount > 0 && (
              <>
                ជាង <b>{team?.builderCount}</b>
              </>
            )}
            {team?.painterCount > 0 && (
              <>
                {" "}
                | ថ្នាំ <b>{team?.painterCount}</b>
              </>
            )}
            {team?.blacksmithCount > 0 && (
              <>
                | ដែក <b>{team?.blacksmithCount}</b>
              </>
            )}
            {team?.carpenterCount > 0 && (
              <>
                {" "}
                | ឈើ <b>{team?.carpenterCount}</b>
              </>
            )}
            {team?.maleWorkerCount > 0 && (
              <>
                {" "}
                | ប្រុស <b>{team?.maleWorkerCount}</b>
              </>
            )}
            {team?.femaleWorkerCount > 0 && (
              <>
                {" "}
                | ស្រី <b>{team?.femaleWorkerCount}</b>
              </>
            )}
          </Col>
        </Row>
        <Row style={{ fontSize: "15px", marginTop: 5 }}>
          <Col xs={6} style={{ padding: "5px 0px 5px 5px" }}>
            &emsp;&nbsp;&nbsp;ការងារ
          </Col>
          <Col xs={18} style={{ padding: "5px 0px 5px 5px" }}>
            :{" "}
            <b>
              {team?.isContract ? (
                <span style={{ color: "rgb(135, 187, 237)" }}>(ម៉ៅ)</span>
              ) : null}{" "}
              {team?.performance}
            </b>
          </Col>
        </Row>
        <Row style={{ fontSize: "15px" }}>
          <Col xs={6} style={{ padding: "5px 0px 5px 5px" }}>
            &nbsp;&nbsp;&nbsp;លទ្ទផល
          </Col>
          <Col xs={18} style={{ padding: "5px 0px 5px 5px" }}>
            : <b>{resultWithIsDone()}</b>
          </Col>
        </Row>
        {/* <Divider style={{ marginTop: "10px" }} /> */}
      </Col>
    </Row>
  );
}
